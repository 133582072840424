<template src="@/../../../templates/brand/components/templates/shop/Order.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapGetters, mapState } from 'vuex'
import accounting from 'accounting'

// logic
export default {
  name: 'MyOrders',
  // component data members
  data () {
    return {
      showOrders: true,
      showCustom: false,
      locales: _.keys(this.$store.state.lang),
      orders: [],
      ordercount: 0
      // reservations: []
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
  },
  methods: {
    orderStatus: function (order) {
      // if(!order.shipDate) return 'in progress';
      if (order.shipDate && !order.receivedDate) return 'en route'
      if (order.receivedDate && !order.paymentReceived)
        return 'received, payment outstanding'
      return order.status
    },
    orderCollections (order) {
      let names = []
      if (order.type === 'reservation') {
        // names = [this.programs.find(c => c._id === order.extradata.collection.id).name]
        names = [order.extradata.preorders.label.en.name]
      } else {
        let urls
        if (order.parent) {
          urls = order.orders.reduce((acc, o) => {
            acc = acc.concat(_.flatten(o.items.map(i => i.collection)))
            return _.uniq(acc)
          }, [])
        } else {
          urls = _.uniq(_.flatten(order.items.map(i => i.collection)))
        }
        names = urls.map(u => {
          let col = this.collections.find(c => c.url === u) || this.programs.find(c => c.url === u)
          if (col) return col.name
          return u
        })
      }
      return names
    },
    currencyLocale: function (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    }
  },
  // beforeRouteEnter (to, from, next) {
  created () {
    // const url = this.user.profile.extradata.canEditReservation ? '/orders' : '/orders?exclude=status:pending,type:reservation'
    const url = '/orders'
    this.$http.get(url).then(res => {
      if (typeof res.data !== 'string') {
        this.orders = res.data.orders
        this.ordercount = res.data.count
      }
    })
    // if (this.$route.name === 'mycustomorders') {
    //   this.$http.get('/orders?type=custom').then(res => {
    //     if (typeof res.data !== 'string') {
    //       this.orders = res.data.orders
    //       this.ordercount = res.data.count
    //     }
    //   })
    //   this.showOrders = false
    //   this.showCustom = true
    // } else {
    //   this.$http.get('/orders?type=standard,credit').then(res => {
    //     if (typeof res.data !== 'string') {
    //       this.orders = res.data.orders
    //       this.ordercount = res.data.count
    //     }
    //   })
    //   this.showOrders = true
    //   this.showCustom = false
    // }
    // next()
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/Order.css"></style>
